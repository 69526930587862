/** @format */

import React from "react";
import { Row, Col } from "react-bootstrap";
import Layout from "../components/Layout";
import { StaticImage } from "gatsby-plugin-image";

export default function SobreNosotros() {
  return (
    <Layout>
      <Row className="mb-5">
        <Col className="my-auto" lg={5}>
          <h3>Nuestro Propósito: </h3>
          <p>
            Desarrollar un canal de distribución de productos LIBRES DE GLUTEN para diferentes marcas internacionales,
            poniendo a disposición sus productos en las principales cadenas comerciales de la región.
          </p>
          <h3>Quienes somos: </h3>
          <p>
            Somos una corporación de Empresas comprometida con el servicio y la salud. Nacimos de la necesidad de poder
            brindar a las personas con alergia al Gluten productos de calidad certificados “Gluten Free” y así puedan
            disfrutar de una vida más saludable. Te conocemos, y queremos brindarte los mejores productos GLUTEN FREE
            para tu distribución o estilo de vida.
          </p>
        </Col>
        <Col>
          <StaticImage src="../images/acerca-de.jpg" alt="Sobre nosotros" />
        </Col>
      </Row>
    </Layout>
  );
}
